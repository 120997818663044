import mitt from "mitt";

const globalMitt = mitt();

const emitGlobal = globalMitt.emit;
const onGlobal = globalMitt.on;

export function useEmitter(){
    return { 
        emit: globalMitt.emit,
        on: globalMitt.on
    }
}

export { 
    emitGlobal,
    onGlobal
}
